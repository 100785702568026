<template>
    <v-app>
        <div>
            <v-row>
                <v-col cols="6" class="justify-end text-end">
                    <v-btn
                            rounded
                            color="#2B2B59"
                            class="white--text"
                            @click="addQuestion"
                    >Thêm câu hỏi mới
                    </v-btn
                    >
                </v-col>
            </v-row>
            <div v-for="(question,index) in questions" :key="index" class="ml-3 ">
                <v-row>
                    <v-col col="9" class=" font-weight-bold content">
                      <span class="text-h5">{{index + 1}}.</span>
                        <div v-html="question.content"></div>
                    </v-col>
                    <v-col class="content d-flex justify-end ">
                        <v-btn @click="editQuestion(question)">
                          <v-icon small>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn @click="deleteQuestion(question)">
                          <v-icon small>mdi-delete</v-icon>
                        </v-btn>
                    </v-col>

                </v-row>
              <v-list>
                <v-list-group
                    :value="false"
                >
                  <template v-slot:activator>
                    <v-list-item-title><strong>Bản dịch</strong></v-list-item-title>
                  </template>
                  <v-list-item>
                    <div v-html="question.content_translate"></div>
                  </v-list-item>
                </v-list-group>
              </v-list>
              <div>
                <v-list-group
                    :value="false"
                >
                  <template v-slot:activator>
                    <v-list-item-title><strong>Đáp án</strong></v-list-item-title>
                  </template>

                  <v-list-item>
                    <div v-html="question.answers[0].content"></div>
                  </v-list-item>
                </v-list-group>

              </div>
              <v-divider></v-divider>
            </div>

        </div>

    </v-app>
</template>
<script>
    import {mapGetters} from "vuex";
    import {
        GET_EXERCISE_CONTENT,
        DELETE_EXERCISE_CONTENT,
    } from "@/store/exercises.module";

    export default {
        data() {
            return {
            };
        },
        computed: {
            ...mapGetters({
                exercise: "exerciseDetail",
                questions: "questionList",
            }),
        },
        methods: {
            addQuestion() {
                this.$router.push({
                    name: "exercise-writing-add",
                    query: {exercise_slug: this.exercise.slug},
                });
            },
            editQuestion(question) {
                this.$router.push({
                    path: "/exercise/writing/add",
                    query: {exercise_slug: this.exercise.slug, question_id: question.id},
                });
            },
            deleteQuestion(item) {
                confirm("Are you sure you want to delete this item?") &&
                this.$store
                    .dispatch(DELETE_EXERCISE_CONTENT, {
                        question_id: item.id,
                        exercise: this.exercise,
                    })
                    .then(() => {
                        this.$store.dispatch(GET_EXERCISE_CONTENT, {
                            exercise_slug: this.exercise.slug,
                        });
                    });
            },
        }
    };
</script>
<style lang="scss">
.content {
  background-color: #ffffff;
}
.set-height-content {
  background-color: #FFFFFF;
  max-height: 100px;
  .table {

  }
}
img {
  max-width: 1000px;
}
    .question-content img {
        width: 150px !important;
    }
</style>
