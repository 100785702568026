var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{attrs:{"method":"post","id":"check-input-form"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.addWord)}}},[_c('v-row',[_c('v-col',{staticClass:"text-center"},[_c('ValidationProvider',{attrs:{"name":"firstWord","rules":"required|minmax:0,10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":'firstWord',"label":"Nhập từ"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.addWord.apply(null, arguments)}},model:{value:(_vm.firstWord),callback:function ($$v) {_vm.firstWord=$$v},expression:"firstWord"}}),_c('span',{staticClass:"validate-error text-danger text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('v-col',{staticClass:"text-center"},[_c('ValidationProvider',{attrs:{"name":"secondWord","rules":"required|minmax:0,10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":'secondWord',"label":"Nhập từ trái nghĩa"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.addWord.apply(null, arguments)}},model:{value:(_vm.secondWord),callback:function ($$v) {_vm.secondWord=$$v},expression:"secondWord"}}),_c('span',{staticClass:"validate-error text-danger text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)],1),_c('div',{staticStyle:{"display":"flex","width":"100%","justify-content":"center"}},[_c('v-btn',{staticClass:"white--text",attrs:{"color":"black","type":"submit","depressed":"","form":"check-input-form"}},[_vm._v("Thêm cặp từ ")])],1)],1)]}}])}),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1 text-center",attrs:{"headers":_vm.headers,"items":_vm.words,"items-per-page":10},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("mdi-delete")])]}},{key:"item.group_type",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{model:{value:(item.group_type),callback:function ($$v) {_vm.$set(item, "group_type", $$v)},expression:"item.group_type"}})]}},{key:"top",fn:function(){return [_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return undefined}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-text',[_c('v-container',[_c('v-row',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{attrs:{"method":"post","id":"check-edit-form"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.close)}}},[_c('v-row',[_c('v-col',{staticClass:"text-center",attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required|minmax:0,10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-col',{attrs:{"cols":"6","sm":"6","md":"12"}},[_c('v-text-field',{attrs:{"label":"Từ"},model:{value:(_vm.editedItem.first_word),callback:function ($$v) {_vm.$set(_vm.editedItem, "first_word", $$v)},expression:"editedItem.first_word"}})],1),_c('span',{staticClass:"validate-error text-danger text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"rules":"required|minmax:0,10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-col',{attrs:{"cols":"6","sm":"6","md":"12"}},[_c('v-text-field',{attrs:{"label":"Từ trái nghĩa"},model:{value:(_vm.editedItem.second_word),callback:function ($$v) {_vm.$set(_vm.editedItem, "second_word", $$v)},expression:"editedItem.second_word"}})],1),_c('span',{staticClass:"validate-error text-danger text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)],1)],1)]}}])})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","type":"submit","depressed":"","form":"check-edit-form"}},[_vm._v("Lưu ")])],1)],1)],1)],1)]},proxy:true}])})],1)],1),_c('div',{staticStyle:{"display":"flex","width":"100%","justify-content":"center"}},[_c('v-btn',{staticClass:"primary",attrs:{"disabled":_vm.disable_edit},on:{"click":_vm.submitWord}},[_vm._v("Cập nhật")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }