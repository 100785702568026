<template>
  <v-app>
    <v-container>
      <v-row>
        <v-col cols="6">
          <div class="font-weight-bold">Tiêu đề</div>
        </v-col>
        <v-col class="text-end" cols="6">
          <v-icon @click="editTitle">mdi-square-edit-outline</v-icon>
        </v-col>
      </v-row>
      <ValidationProvider name="name" rules="required" v-slot="{ errors }">
        <v-row>
          <v-col cols="12">
            <div v-if="!isEdited">{{ exercise.title }}</div>
          </v-col>
          <v-col>
            <v-text-field
                v-if="isEdited"
                v-model="exercise.title"
                label="Điền tiêu đề"
            ></v-text-field>
            <span style=" margin-top: -20px; position: absolute"
                  class="validate-error text-danger text-danger">
              {{ errors[0] }}
            </span>
            <div style="display: flex;width: 100%;justify-content: center">
              <v-btn v-if="isEdited" @click="submitTitle">Lưu</v-btn>
            </div>
          </v-col>
        </v-row>
      </ValidationProvider>
      <component v-bind:is="ExerciseComponent"></component>
    </v-container>
  </v-app>
</template>

<script>
import {mapGetters} from "vuex";
import {
  UPDATE_EXERCISE,
  GET_EXERCISE_DETAIL,
  GET_EXERCISE_CONTENT,
  GET_FILLED_WORD_LIST,
} from "@/store/exercises.module";
import {EXERCISE} from "@/common/constants";
import translateExerciseFillWord from "@/components/exercise-model/translate-exercise-fill-word";
import translateExerciseQuestionWord from "@/components/exercise-model/translate-exercise-question";
import readingPrivateTopicIIExercise from "@/components/exercise-model/reading-private-topic-II-exercise";
import translateExercise from "@/components/exercise-model/translate-exercise";
import wordLinkExerciseManyGroup from "@/components/exercise-model/word-link-exercise-many-group";
import wordLinkExerciseOneGroup from "@/components/exercise-model/word-link-exercise-one-group";
import listeningExercise from "@/components/exercise-model/listening-exercise.vue";
import flashcardExercise from "@/components/exercise-model/flashcard-exercise.vue";
import readingExercise from "@/components/exercise-model/reading-exercise.vue";
import writingExercise from "@/components/exercise-model/writing-exercise.vue";
import GrammarExercise from "@/components/exercise-model/grammar-exercise-question.vue";
import ListeningAndChooseExercise from "@/components/exercise-model/listening-and-choose-answer-exercise.vue";
import ListeningPrivateAndChooseExercise
  from "@/components/exercise-model/listening-private-and-choose-answer-exercise.vue";

export default {
  data() {
    return {
      isEdited: false,
      ExerciseComponent: "",
    };
  },
  computed: {
    ...mapGetters({
      exercise: "exerciseDetail",
    }),
  },
  async created() {
    await this.$store.dispatch(GET_EXERCISE_DETAIL, {
      lesson_slug: this.$route.query.lesson_slug,
    });
    await this.$store.dispatch(GET_EXERCISE_CONTENT, {
      exercise_slug: this.exercise.slug,
    });
    await this.$store.dispatch(GET_FILLED_WORD_LIST, {
      exercise_slug: this.exercise.slug,
    });
    await this.$store.dispatch(GET_EXERCISE_DETAIL, {
      exercise_slug: this.exercise.slug,
    });
    await this.switchExercise();
  },
  components: {
    wordLinkExerciseManyGroup,
    wordLinkExerciseOneGroup,
    translateExercise,
    translateExerciseFillWord,
    translateExerciseQuestionWord,
    listeningExercise,
    flashcardExercise,
    readingExercise,
    writingExercise,
    GrammarExercise,
    ListeningAndChooseExercise,
    ListeningPrivateAndChooseExercise,
    readingPrivateTopicIIExercise
  },

  methods: {
    editTitle() {
      this.isEdited = !this.isEdited;
    },
    submitTitle() {
      if (this.exercise.title === '') {
        alert('Không được bỏ trống trường này');
      } else {
        this.isEdited = !this.isEdited;
        this.$store.dispatch(UPDATE_EXERCISE, {
          exercise: this.exercise,
        });
      }

    },
    switchExercise() {
      switch (this.exercise.type) {
        case EXERCISE.WORD_LINK_ONE:
          this.ExerciseComponent = "wordLinkExerciseOneGroup";
          break;
        case EXERCISE.WORD_LINK_MANY:
          this.ExerciseComponent = "wordLinkExerciseManyGroup";
          break;
        case EXERCISE.TRANSLATE_FILL_WORD:
          this.ExerciseComponent = "translateExerciseFillWord";
          break;
        case EXERCISE.GRAMMAR:
          this.ExerciseComponent = "GrammarExercise";
          break;
        case EXERCISE.READING_II_COMMON:
          this.ExerciseComponent = "translateExerciseQuestionWord";
          break;
        case EXERCISE.READING_II_PRIVATE:
          this.ExerciseComponent = "readingPrivateTopicIIExercise";
          break;
        case EXERCISE.TRANSLATE_IMAGE_SENTENCE:
          this.ExerciseComponent = "translateExercise";
          break;
        case EXERCISE.TRANSLATE_IMAGE_TEXT:
          this.ExerciseComponent = "translateExercise";
          break;
        case EXERCISE.TRANSLATE_TEXT_TEXT:
          this.ExerciseComponent = "translateExercise";
          break;
        case EXERCISE.TRANSLATE_SENTENCE_SENTENCE:
          this.ExerciseComponent = "translateExercise";
          break;
        case EXERCISE.LISTENING:
          this.ExerciseComponent = "listeningExercise";
          break;
        case EXERCISE.LISTENING_AND_CHOOSE_ANSWER_COMMON:
          this.ExerciseComponent = "ListeningAndChooseExercise";
          break;
        case EXERCISE.LISTENING_AND_CHOOSE_ANSWER_PRIVATE:
          this.ExerciseComponent = "ListeningPrivateAndChooseExercise";
          break;
        case EXERCISE.FLASHCARD:
          this.ExerciseComponent = "flashcardExercise";
          break;
        case EXERCISE.READING:
          this.ExerciseComponent = "readingExercise";
          break;
        case EXERCISE.WRITING:
          this.ExerciseComponent = "writingExercise";
          break;
        default:
          break;
      }
    },
  },
};
</script>
<style></style>
