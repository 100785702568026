<template>
  <v-app>
    <div>
      <v-row>
        <v-col cols="6" class="justify-end text-end">
          <v-btn
              rounded
              color="#2B2B59"
              class="white--text"
              @click="addQuestion"
          >Thêm câu hỏi mới
          </v-btn
          >
        </v-col>
      </v-row>
      <!-- check answer block -->
      <v-row>
        <v-col>
          <div
              v-for="(question,index) in questions"
              :key="question.id"
              class="question-group"
          >
            <v-row class="ma-20" no-gutters>
              <v-col>
                <h4>Câu hỏi số {{ index + 1 }}</h4>
                <h5>1.Nội dung bài đọc</h5>
                <div v-html="question.content" class="black--text text-body-1"></div>
                <h5>
                  2.Bản dịch
                </h5>
                <div v-html="question.content_translate" class="black--text text-body-1"></div>
              </v-col>
              <v-col class="justify-end text-end">
                <v-btn @click="editQuestion(question)">
                  <v-icon small class="mr-2">mdi-pencil</v-icon>
                </v-btn>
                <v-btn @click="deleteQuestion(question)">
                  <v-icon small>mdi-delete</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <!-- fill answer block -->
            <v-row class="ma-20 black--text text-body-1" no-gutters>
              <h6>Đáp án</h6>
              <ul class="pl-10" id="example-1">
                <li v-for="answer in question.answers" :key="answer.id">
                  <div>
                    <span
                        :class="[answer.check==1 || answer.check == -1 ? 'green--text' : '']">{{ answer.content }}</span>
                    <span v-if="answer.check==1 || answer.check == -1">(Đáp án đúng)</span>
                  </div>
                  <div v-html="answer.description"></div>
                </li>
              </ul>
            </v-row>
            <v-divider/>
          </div>
        </v-col>
      </v-row>
    </div>

  </v-app>
</template>
<script>
import {mapGetters} from "vuex";
import {
  GET_EXERCISE_CONTENT,
  DELETE_EXERCISE_CONTENT,
} from "@/store/exercises.module";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      exercise: "exerciseDetail",
      questions: "questionList",
    }),
  },
  created() {
    if (this.questions.length > 0) {
      this.listQuestionGroup = this.questions.filter(item => {
        if (item.question_type == 1) {
          return item
        }
      })
      console.log(this.listQuestionGroup, 'this.listQuestionGroup')
      this.listQuestionNoGroup = this.questions.filter(item => {
        if (item.question_type == 2) {
          return item
        }
      })
    }
  },
  methods: {
    addQuestion() {
      this.$router.push({
        path: "/exercise/translate/question-word/add",
        query: {exercise_slug: this.exercise.slug},
      });
    },
    editQuestion(question) {
      this.$router.push({
        path: "/exercise/translate/question-word/add",
        query: {exercise_slug: this.exercise.slug, question_id: question.id},
      });
    },
    deleteQuestion(item) {
      confirm("Are you sure you want to delete this item?") &&
      this.$store
          .dispatch(DELETE_EXERCISE_CONTENT, {
            question_id: item.id,
            exercise: this.exercise,
          })
          .then(() => {
            this.$store.dispatch(GET_EXERCISE_CONTENT, {
              exercise_slug: this.exercise.slug,
            });
          });
    },
  }
};
</script>
<style>
.question-content img {
  width: 150px !important;
}
</style>
