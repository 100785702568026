<template>
  <v-app>
    <v-container>
      <!-- header ----add list filled word -->
      <ValidationObserver v-slot="{ handleSubmit }">
        <v-form
            @submit.prevent="handleSubmit( addWord )"
            method="post"
            id="check-input-form"
        >
          <ValidationProvider name="name" rules="required" v-slot="{ errors }">
            <div class="font-weight-bold">Nhập các từ cần điền vào ô bên dưới</div>
            <v-row>
              <v-col>
                <v-text-field
                    v-model="filledWord.content"
                    label="Điền từ"
                    @keyup.enter="addWord"
                ></v-text-field
                >
              </v-col>
            </v-row>
            <span class="validate-error text-danger text-danger">
              {{ errors[0] }}
            </span>
          </ValidationProvider>
          <v-col>
            <v-btn color="black" class="white--text"
                   type="submit"
                   depressed
                   width="200"
                   form="check-input-form">Thêm từ
            </v-btn>
          </v-col>
        </v-form>
      </ValidationObserver>
      <v-row>
        <v-col>
          <div>
            <!-- <v-chip
              v-for="item in filledWords"
              :key="item.id"
              class="ma-2"
              color="green"
              text-color="white"
            >
              {{ item }}
            </v-chip> -->
            <v-chip
                v-for="item in filledWords"
                :key="item.id"
                close
                class="ma-2"
                color="green"
                @click:close="remove(item)"
            >
              <strong>{{ item.content }}</strong>
            </v-chip>
          </div>
        </v-col>
      </v-row>
      <!-- main --- add button -->
      <v-row>
        <v-col class="text-center">
          <v-dialog v-model="dialog" width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="black" class="white--text" @click="addQuestion" width="200px"
              >Thêm câu hỏi
              </v-btn
              >
            </template>
            <v-card>
              <v-card-text class="headline pt-10">
                <v-alert type="warning">Bạn chưa có danh sách từ cần điền</v-alert>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="dialog = false"> Đóng</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
      <!-- main --- question table -->
      <v-row>
        <v-col cols="12">
          <v-data-table
              :headers="headers"
              :items="questions"
              :items-per-page="5"
              class="elevation-1"
          >
            <template v-slot:item.actions="{ item }">
              <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
              <v-icon small @click="deleteQuestion(item)">mdi-delete</v-icon>
            </template>
            <template v-slot:item.content="{ item }">
              <div class="question-content" v-html="item.content"></div>
            </template>
            <template v-slot:item.answers="{ item }">
              <v-list-item v-for="i in item.answers" :key="i.id">
                <v-list-item-content>
                  <v-list-item-title
                  >
                    <div>
                      <strong>{{ i.content }}</strong>
                    </div>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>
<script>
import {mapGetters} from "vuex";
import {
  SAVE_FILLED_WORD,
  DELETE_FILLED_WORD,
  DELETE_EXERCISE_CONTENT,
  GET_EXERCISE_CONTENT,
  GET_FILLED_WORD_LIST,
} from "@/store/exercises.module";

export default {
  data() {
    return {
      dialog: false,
      filledWord: {
        content: "",
        exercise_id: -1,
      },
      headers: [
        {text: "Câu hỏi", value: "content"},
        {text: "Đáp án", value: "answers"},
        {text: "Thao tác", value: "actions", sortable: false},
      ],
    };
  },
  computed: {
    ...mapGetters({
      exercise: "exerciseDetail",
      questions: "questionList",
      filledWords: "filledWordList",
    }),
  },
  methods: {
    addQuestion() {
      if (this.filledWords.length <= 0) {
        this.dialog = true;
        return false;
      }
      this.$router.push({
        path: "/exercise/translate/filled-word/add",
        query: {exercise_slug: this.exercise.slug},
      });
    },
    addWord() {
      this.filledWord.exercise_id = this.exercise.id;
      this.$store
          .dispatch(SAVE_FILLED_WORD, {
            filled_word: this.filledWord,
          })
          .then((data) => {
            if (data.data.status == 1) {
              this.$store.dispatch(GET_FILLED_WORD_LIST, {
                exercise_slug: this.exercise.slug,
              });
            }
          });
      this.filledWord = {};
    },
    editItem(item) {
      this.$router.push({
        path: "/exercise/translate/filled-word/add",
        query: {exercise_slug: this.exercise.slug, question_id: item.id},
      });
    },
    deleteQuestion(item) {
      confirm("Are you sure you want to delete this item?") &&
      this.$store
          .dispatch(DELETE_EXERCISE_CONTENT, {
            question_id: item.id,
            exercise: this.exercise,
          })

          .then(() => {
            this.$store.dispatch(GET_EXERCISE_CONTENT, {
              exercise_slug: this.exercise.slug,
            });
          });
    },
    remove(item) {
      this.$store
          .dispatch(DELETE_FILLED_WORD, {
            filled_word: item,
          })
          .then((data) => {
            if (data.data.status == 1) {
              this.$store.dispatch(GET_FILLED_WORD_LIST, {
                exercise_slug: this.exercise.slug,
              });
            }
          });
    },
  },
};
</script>
<style lang="scss">
.question-content img {
  width: 150px !important;
}
</style>
