<template>
  <div>
    <ValidationObserver v-slot="{ handleSubmit }">
      <v-form
          @submit.prevent="handleSubmit(addWord)"
          method="post"
          id="check-input-form"
      >
        <v-row>
          <v-col class="text-center">
            <ValidationProvider name="firstWord"  rules="required|minmax:0,10" v-slot="{ errors }">
              <v-text-field
                  :name="'firstWord'"
                  v-model="firstWord"
                  @keyup.enter="addWord"
                  label="Nhập từ"
              ></v-text-field>
              <span class="validate-error text-danger text-danger">
              {{ errors[0] }}
            </span>
            </ValidationProvider>
          </v-col>
          <v-col class="text-center">
            <ValidationProvider name="secondWord" rules="required|minmax:0,10" v-slot="{ errors }">
              <v-text-field
                  :name="'secondWord'"
                  @keyup.enter="addWord"
                  v-model="secondWord"
                  label="Nhập từ trái nghĩa"
              ></v-text-field>
              <span class="validate-error text-danger text-danger">
              {{ errors[0] }}
            </span>
            </ValidationProvider>
          </v-col>
        </v-row>
        <div style="display: flex;width: 100%; justify-content: center">
          <v-btn color="black" class="white--text" type="submit"
                 depressed
                 form="check-input-form"
          >Thêm cặp từ
          </v-btn>
        </div>
      </v-form>
    </ValidationObserver>

    <v-row>
      <v-col cols="12">
        <v-data-table
            :headers="headers"
            :items="words"
            :items-per-page="10"
            class="elevation-1 text-center"
        >
          <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)"
            >mdi-pencil
            </v-icon
            >
            <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
          </template>
          <template v-slot:item.group_type="{ item }">
            <v-checkbox v-model="item.group_type"></v-checkbox>
          </template>
          <template v-slot:top>
            <v-dialog v-model="dialog" max-width="500px">
              <template v-slot:activator="{ on, attrs }"></template>
              <v-card>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <ValidationObserver v-slot="{ handleSubmit }">
                        <v-form
                            @submit.prevent="handleSubmit(close)"
                            method="post"
                            id="check-edit-form"
                        >
                          <v-row>
                            <v-col cols="6" class="text-center">
                              <ValidationProvider rules="required|minmax:0,10" v-slot="{ errors }">

                                <v-col cols="6" sm="6" md="12">
                                  <v-text-field
                                      v-model="editedItem.first_word"
                                      label="Từ"
                                  ></v-text-field>
                                </v-col>
                                <span class="validate-error text-danger text-danger">
              {{ errors[0] }}
            </span>

                              </ValidationProvider>
                            </v-col>
                            <v-col cols="6" class="text-center">
                              <ValidationProvider  rules="required|minmax:0,10" v-slot="{ errors }">

                                <v-col cols="6" sm="6" md="12">
                                  <v-text-field
                                      v-model="editedItem.second_word"
                                      label="Từ trái nghĩa"
                                  ></v-text-field>
                                </v-col>
                                <span class="validate-error text-danger text-danger">
                                  {{ errors[0] }}
                                </span>
                              </ValidationProvider>
                            </v-col>
                          </v-row>
                        </v-form>
                      </ValidationObserver>
                    </v-row>
                  </v-container>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text type="submit"
                           depressed
                           form="check-edit-form">Lưu
                    </v-btn>
                  </v-card-actions>
                </v-card-text>


              </v-card>
            </v-dialog>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <div style="display: flex;width: 100%; justify-content: center">
      <v-btn :disabled="disable_edit" class="primary" @click="submitWord">Cập nhật</v-btn>
    </div>

<!--      </v-col>-->
<!--    </v-row>-->
  </div>
</template>
<script>
import {UPDATE_EXERCISE_CONTENT} from "@/store/exercises.module";
import {mapGetters} from "vuex";

export default {
  data() {
    return {
      disable_edit:true,
      edit_first_word:"",
      edit_second_word:"",
      index: -1,
      isEdited: false,
      editedItem: {
        id: 0,
        first_word: "",
        second_word: "",
      },
      message: {
        success: "",
        error: "",
      },
      editedIndex: -1,
      dialog: false,
      firstWord: "",
      secondWord: "",
      headers: [
        {text: "Từ", value: "first_word"},
        {text: "Từ trái nghĩa", value: "second_word"},
        {text: "Thao tác", value: "actions", sortable: false},
      ],
    };
  },
  watch: {

  },
  computed: {
    ...mapGetters({
      exercise: "exerciseDetail",
      words: "wordPairList",
    }),
  },
  methods: {
    addWord() {
      this.words.push({
        id: this.index--,
        first_word: this.firstWord,
        second_word: this.secondWord,
        exercise_id: this.exercise.id,
      });
      this.firstWord = "";
      this.secondWord = "";
      this.disable_edit = false;
    },
    editItem(item) {

      this.editedIndex = this.words.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.edit_first_word = item.first_word;
      this.edit_second_word = item.second_word;
      this.dialog = true;
    },
    close() {
      this.words[this.editedIndex].first_word = this.editedItem.first_word;
      this.words[this.editedIndex].second_word = this.editedItem.second_word;
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
      if(this.edit_first_word !== this.words[this.editedIndex].first_word || this.edit_second_word !== this.words[this.editedIndex].second_word){
        this.disable_edit= false
      }
    },
    editTitle() {
      this.isEdited = !this.isEdited;
    },
    confirmDelete(item) {
      this.disable_edit = false;
      this.editedIndex = this.words.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.words.splice(this.editedIndex, 1);
    },
    deleteItem(item) {
      confirm("Are you sure you want to delete this item?") &&
      this.confirmDelete(item);

    },
    submitWord() {
      this.$store
          .dispatch(UPDATE_EXERCISE_CONTENT, {
            word_groups: this.words,
            exercise: this.exercise,
          })
          .then((data) => {
            this.disable_edit = true;
            if (data.data.status === 1) {
              this.$toasted.success('Cập nhật thành công', {
                position: "top-right",
                duration: 3000
              })
            }
            if (data.data.status === 0) {
              this.$toasted.error('Cập nhật thất bại', {
                position: "top-right",
                duration: 3000
              })
            }
          });
    },
  },
};
</script>
<style>
.question-content img {
  width: 150px !important;
}
</style>
