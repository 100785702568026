var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-container',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{attrs:{"method":"post","id":"check-input-form"},on:{"submit":function($event){$event.preventDefault();return handleSubmit( _vm.addWord )}}},[_c('ValidationProvider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"font-weight-bold"},[_vm._v("Nhập các từ cần điền vào ô bên dưới")]),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"Điền từ"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.addWord.apply(null, arguments)}},model:{value:(_vm.filledWord.content),callback:function ($$v) {_vm.$set(_vm.filledWord, "content", $$v)},expression:"filledWord.content"}})],1)],1),_c('span',{staticClass:"validate-error text-danger text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)}),_c('v-col',[_c('v-btn',{staticClass:"white--text",attrs:{"color":"black","type":"submit","depressed":"","width":"200","form":"check-input-form"}},[_vm._v("Thêm từ ")])],1)],1)]}}])}),_c('v-row',[_c('v-col',[_c('div',_vm._l((_vm.filledWords),function(item){return _c('v-chip',{key:item.id,staticClass:"ma-2",attrs:{"close":"","color":"green"},on:{"click:close":function($event){return _vm.remove(item)}}},[_c('strong',[_vm._v(_vm._s(item.content))])])}),1)])],1),_c('v-row',[_c('v-col',{staticClass:"text-center"},[_c('v-dialog',{attrs:{"width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{staticClass:"white--text",attrs:{"color":"black","width":"200px"},on:{"click":_vm.addQuestion}},[_vm._v("Thêm câu hỏi ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-text',{staticClass:"headline pt-10"},[_c('v-alert',{attrs:{"type":"warning"}},[_vm._v("Bạn chưa có danh sách từ cần điền")])],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Đóng")])],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.questions,"items-per-page":5},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteQuestion(item)}}},[_vm._v("mdi-delete")])]}},{key:"item.content",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"question-content",domProps:{"innerHTML":_vm._s(item.content)}})]}},{key:"item.answers",fn:function(ref){
var item = ref.item;
return _vm._l((item.answers),function(i){return _c('v-list-item',{key:i.id},[_c('v-list-item-content',[_c('v-list-item-title',[_c('div',[_c('strong',[_vm._v(_vm._s(i.content))])])])],1)],1)})}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }