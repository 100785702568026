<template>
  <v-app>
    <v-container>
      <!-- material block -->
      <v-row>
        <div class="h5">I.Tiêu đề nhóm</div>
      </v-row>
      <v-row>
        <v-col>
          <CkContent
              validateName="questionContent"
              :content="material.subtitle"
              @getData="getSubtitleContent"
          ></CkContent>
        </v-col>
      </v-row>
      <v-row class="justify-center text-center"
      >
        <v-col>
          <v-btn color="indigo" class="white--text" @click="submitMaterial"
          >Cập nhật tài liệu
          </v-btn>
        </v-col
        >
      </v-row>
      <v-row class="align-center justify-center">
        <v-btn
            rounded
            color="#2B2B59"
            class="white--text"
            @click="addQuestion"
        >Thêm bài nghe mới
        </v-btn>
      </v-row>
      <div v-for="(reading,index) in listListing" :key="index">
        <v-row>
          <v-col>
            <h4>Bài nghe số {{ index + 1 }}</h4>
          </v-col>
          <v-col class="justify-end text-end">
            <v-btn @click="editQuestion(reading)" width="100px">
              <v-icon small class="mr-2">mdi-pencil</v-icon>
            </v-btn>
            <v-btn @click="deleteQuestion(reading)" width="100px">
              <v-icon small>mdi-delete</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <h5>1.Nội dung bài đọc</h5>
        <v-row>
          <v-col cols="5">
            <vuetify-audio
                :file="reading.material.audio.src"
                color="success"
                downloadable
            ></vuetify-audio>
          </v-col>
        </v-row>
        <h5 class="mt-5">2.Danh sách câu hỏi</h5>
        <div
            v-if="reading && reading.questions && reading.questions.length > 0"
            v-for="(question,index) in reading.questions"
            :key="question.id"
            class="question-group"
        >
          <v-row class="ma-20" no-gutters>
            <v-col>
              <div class="black--text text-body-1 ">
                <span class="text-body-1 font-weight-bold">Câu hỏi số {{ index + 1 }} :</span>
                <span
                  v-html="question.content"
              ></span>
              </div>
            </v-col>
          </v-row>
          <!-- check answer  -->
          <v-row class="ma-20" no-gutters>
            <div>
              <div class="black--text text-body-1 font-weight-bold">Đáp án</div>
              <ul class="pl-10 black--text text-body-1">
                <li v-for="answer in question.answers" :key="answer.id">
                  <div v-if="question.question_type == 1" class="my-3">
                    <img :src="answer.url" width="100px"> <span
                      v-if="answer.check==1 || answer.check == -1">(Đáp án đúng)</span>
                  </div>
                  <div v-if="question.question_type == 2">
                    <div :class="[answer.check==1 || answer.check == -1 ? 'green--text' : '']">
                      {{ answer.content }} ({{ answer.description }})
                    </div>
                    <span v-if="answer.check==1 || answer.check == -1">(Đáp án đúng)</span>
                  </div>
                </li>
              </ul>
            </div>
          </v-row>
        </div>
        <v-divider/>
      </div>
    </v-container>
  </v-app>
</template>
<script>
import {mapGetters} from "vuex";
import CkContent from "@/components/ck-content";
import {
  DELETE_EXERCISE_LISTENING,
  UPDATE_EXERCISE_MATERIAL,
  GET_EXERCISE_LIST_LISTING,
  GET_EXERCISE_CONTENT
} from "@/store/exercises.module";


export default {
  components: {
    CkContent,
    VuetifyAudio: () => import("vuetify-audio"),
  },
  async created() {
    if (this.exercise) {
      await this.$store.dispatch(GET_EXERCISE_LIST_LISTING, {
        exercise_slug: this.exercise.slug,
      });
    }
    if (this.questions.length > 0) {
      this.listQuestionGroup = this.questions.filter(item => {
        if (item.question_type == 1) {
          return item
        }
      })
      this.listQuestionNoGroup = this.questions.filter(item => {
        if (item.question_type == 2) {
          return item
        }
      })
    }
  },
  data() {
    return {
      subtitle: "",
      translated_subtitle: "",
      dialog1: false,
      dialog2: false,
      fileUpload: {},
      audio: {
        src: "",
      },
      dialog: false,
    };
  },
  computed: {
    ...mapGetters({
      exercise: "exerciseDetail",
      material: "materialDetail",
      questions: "questionList",
      listListing: "listListing",
    }),
  },
  methods: {
    addQuestion() {
      this.$router.push({
        name: "exercise-listening-private-and-choose-answer-add",
        query: {
          exercise_slug: this.exercise.slug,
          index: this.getMaxKey() + 1
        },
      });
    },
    editQuestion(question) {
      this.$router.push({
        path: "/exercise/listening-private-and-choose-answer/add",
        query: {
          exercise_slug: this.exercise.slug,
          question_id: question.id,
          index: question.material.index
        },
      });
    },
    getSubtitleContent(value) {
      this.material.subtitle = value;
      this.material.translated_subtitle = value
    },
    submitMaterial() {
      if (!this.material.subtitle) {
        this.$toasted.error('Chưa nhập tiều đề', {
          position: "top-right",
          duration: 3000
        })
        return false
      }
      if (this.material.subtitle) {
        this.$store.dispatch(UPDATE_EXERCISE_MATERIAL, {
          exercise: this.exercise,
          audio: this.material.audio.src,
          material: this.material,
        }).then((data) => {
          if (data.status) {
            this.$store.dispatch(GET_EXERCISE_CONTENT, {
              exercise_slug: this.exercise.slug,
            });
            this.$toasted.success('Upload tài liệu thành công', {
              position: "top-right",
              duration: 3000
            })
          }
        });
      }

    },
    deleteQuestion(item) {
      confirm("Are you sure you want to delete this item?") &&
      this.$store
          .dispatch(DELETE_EXERCISE_LISTENING, {
            index: item.material.index,
            exercise_id: item.material.exercise_id,
          })
          .then(() => {
            this.$store.dispatch(GET_EXERCISE_LIST_LISTING, {
              exercise_slug: this.exercise.slug,
            });
          });
    },
    getMaxKey() {
      let max = 1
      if (this.listListing.length == 0) {
        return 0
      }
      this.listListing.map((item) => {
        if (item.material.index >= max) {
          max = item.material.index
        }
      })
      return max
    },
  },
};
</script>
<style>
.question-content img {
  width: 150px !important;
}
</style>
