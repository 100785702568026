<template>
  <v-app>
    <v-container>
      <!-- header --- add button -->
      <v-row>
        <v-col class="text-center">
          <v-btn color="black" class="white--text" @click="addQuestion"
            >Thêm câu hỏi</v-btn
          >
        </v-col>
      </v-row>
      <!-- body --- question table -->
      <v-row>
        <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="questions"
          :items-per-page="5"
          class="elevation-1"
        >
          <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)"
              >mdi-pencil</v-icon
            >
            <v-icon small @click="deleteQuestion(item)">mdi-delete</v-icon>
          </template>
          <template v-slot:item.content="{ item }">
            <div class="question-content" v-html="item.content"></div>
          </template>
          <template v-slot:item.answers="{ item }">
            <v-list-item v-for="i in item.answers" :key="i.id">
              <v-list-item-content>
                <v-list-item-title><div v-html="i.content"></div></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>
<script>
import { mapGetters } from "vuex";
import {
  GET_EXERCISE_CONTENT,
  DELETE_EXERCISE_CONTENT,
} from "@/store/exercises.module";
export default {
  data() {
    return {
      headers: [
        { text: "STT", value: "id" },
        { text: "Tiêu đề" , value: "content" },
        { text: "Đáp án", value: "answers" },
        { text: "Thao tác", value: "actions", sortable: false },
      ],
    };
  },
  computed: {
    ...mapGetters({
      exercise: "exerciseDetail",
      questions: "questionList",
    }),
  },
  methods: {
    addQuestion() {
      this.$router.push({
        path: "/exercise/translate/add",
        query: { exercise_slug: this.exercise.slug },
      });
    },
    editItem(question){
       this.$router.push({
        path: "/exercise/translate/add",
        query: { exercise_slug: this.exercise.slug, question_id: question.id },
      });
    },
    deleteQuestion(item) {
       confirm("Are you sure you want to delete this item?") &&
      this.$store
        .dispatch(DELETE_EXERCISE_CONTENT, {
          question_id: item.id,
          exercise: this.exercise,
        })
        .then(() => {
           this.$store
        .dispatch( GET_EXERCISE_CONTENT, {
          exercise_slug: this.exercise.slug,
        })
        });
    },
  },
};
</script>
<style >
  .question-content img{
    width: 150px !important;
  }
</style>