<template>
  <v-app>
    <div>
      <v-row>
        <v-col cols="6" class="justify-end text-end">
          <v-btn
              rounded
              color="#2B2B59"
              class="white--text"
              @click="addQuestion"
          >Thêm bài đọc mới
          </v-btn
          >
        </v-col>
      </v-row>
      <div v-for="(reading,index) in listReading">
        <v-row>
          <v-col>
            <h4>Câu hỏi số {{ index + 1 }}</h4>
          </v-col>
          <v-col class="justify-end text-end">
            <v-btn @click="editQuestion(reading)" width="100px">
              <v-icon small class="mr-2">mdi-pencil</v-icon>
            </v-btn>
            <v-btn @click="deleteQuestion(reading)" width="100px">
              <v-icon small>mdi-delete</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <h5>1.Nội dung bài đọc</h5>
        <div v-html="reading.material ? reading.material.content : ''" class="black--text text-body-1"></div>
        <h5>2.Danh sách câu hỏi</h5>
        <div
            v-if="reading && reading.questions && reading.questions.length > 0"
            v-for="question in reading.questions"
            :key="question.id"
            class="question-group"
        >
          <v-row class="ma-20" no-gutters>
            <v-col>
              <div class="black--text text-list-question">
                Câu hỏi số {{ question.id }} :<span style="font-weight: 400"
                  v-html="question.content"
              ></span>
              </div>
            </v-col>
          </v-row>
          <!-- check answer  -->
          <v-row class="ma-20" no-gutters>
            <div>
              <div class="black--text text-body-1 font-weight-bold">Đáp án</div>
              <ul class="pl-10 black--text text-body-1">
                <li v-for="answer in question.answers" :key="answer.id">
                  <span v-if="answer.check == 1" class="green--text">{{
                      answer.content
                    }}({{ answer.description }})</span>
                  <span v-else>{{ answer.content }}({{ answer.description }})</span>
                </li>
              </ul>
            </div>
          </v-row>
        </div>
        <v-divider/>
      </div>

    </div>

  </v-app>
</template>
<script>
import {mapGetters} from "vuex";
import {
  GET_EXERCISE_CONTENT,
  GET_EXERCISE_LIST_READING,
  DELETE_EXERCISE_CONTENT_READING,
  DELETE_EXERCISE_READING
} from "@/store/exercises.module";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      exercise: "exerciseDetail",
      questions: "questionList",
      listReading: "listReading",
    }),
  },
  async created() {
    if (this.exercise) {
      await this.$store.dispatch(GET_EXERCISE_LIST_READING, {
        exercise_slug: this.exercise.slug,
      });
    }
    if (this.questions.length > 0) {
      this.listQuestionGroup = this.questions.filter(item => {
        if (item.question_type == 1) {
          return item
        }
      })
      this.listQuestionNoGroup = this.questions.filter(item => {
        if (item.question_type == 2) {
          return item
        }
      })
    }
  },
  methods: {
    getMaxKey() {
      let max = 1
      if (this.listReading.length == 0) {
        return 0
      }
      this.listReading.map((item) => {
        if (item.material.index >= max) {
          max = item.material.index
        }
      })
      return max
    },
    addQuestion() {
      this.$router.push({
        path: "/exercise/reading-private/add",
        query: {
          exercise_slug: this.exercise.slug,
          index: this.getMaxKey() + 1
        },
      });
    },
    editQuestion(question) {
      this.$router.push({
        path: "/exercise/reading-private/add",
        query: {
          exercise_slug: this.exercise.slug,
          index: question.material.index
        },
      });
    },
    deleteQuestion(item) {
      confirm("Are you sure you want to delete this item?") &&
      this.$store
          .dispatch(DELETE_EXERCISE_CONTENT_READING, {
            index: item.material.index,
            exercise_id: item.material.exercise_id,
          })
          .then(() => {
            this.$store
                .dispatch(DELETE_EXERCISE_READING, {
                  index: item.material.index,
                  exercise_id: item.material.exercise_id,
                })
            this.$store.dispatch(GET_EXERCISE_LIST_READING, {
              exercise_slug: this.exercise.slug,
            });
          });
    },
  }
};
</script>
<style>
.question-content img {
  width: 150px !important;
}
.text-list-question {
  font-size: 15px!important;
  font-weight:500 !important;
}
</style>
