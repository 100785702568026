<template>
  <div>
    <v-row class="align-center justify-content-end">
      <v-col>
        <HnrButton
          class="rounded-pill white--text"
          :color="'#2B2B59'"
          :text="addQuestionText"
          @hnrClickEvent="addQuestion"
          :width="'200px'"
        />
      </v-col>
    </v-row>
    <div  style="margin-bottom: 20px" v-for="(word, key,i) in words" v-bind:key="key">
      <div style="margin-bottom: 20px ; display: flex;width: 100%;justify-content: space-between">
        <h3>Câu {{ i + 1 }}</h3>
        <div>
          <v-icon @click="editQuestion(key)">mdi-square-edit-outline</v-icon>
          <v-icon @click="deleteQuestion(word)">mdi-delete</v-icon>
        </div>

      </div>
      <v-row >
        <v-col cols="3" v-for="(item, index) in word" class="border-table" v-bind:key="index+ 'a'"
          ><span
            :class="[
              item.group_type ? 'font-weight-bold green--text' : '',
              'text-body-1'
            ]"
            >{{ item.content }}</span
          ></v-col
        >
      </v-row>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import HnrButton from "../elements/hnr-button";
import {DELETE_QUESTION_GROUP} from '../../store/questions.module'
import {GET_EXERCISE_CONTENT} from "@/store/exercises.module";

export default {
  data() {
    return {
      addQuestionText: "Thêm câu hỏi mới"
    };
  },
  components: { HnrButton },
  computed: {
    ...mapGetters({
      words: "wordLinkList",
      exerciseGetter: "exerciseDetail"
    })
  },
  watch:{
    words(val){
      console.log(val,"words")
},
    exerciseGetter(val){
      console.log(val,"aaaa")
    }
  },
  methods: {
    deleteQuestion(val){
      let params ={
         exercise_id:val[0].exercise_id,
         group:val[0].group
      }
      this.$store.dispatch(DELETE_QUESTION_GROUP,params).then(response=>{
        this.$toasted.show(response.message, {
          theme: "bubble",
          position: "top-right",
          duration : 3000
        })
        this.$store.dispatch(GET_EXERCISE_CONTENT, {
          exercise_slug: this.exerciseGetter.slug,
        });
      })

    },
    addQuestion() {
      this.$router.push({
        name: "exercise-exercise-work-link-group-add",
        query: { exercise_slug: this.exerciseGetter.slug }
      });
    },
    editQuestion(key) {
      this.$router.push({
        name: "exercise-exercise-work-link-group-add",
        query: {
          exercise_slug: this.exerciseGetter.slug,
          group: key
        }
      });
    }
  }
};
</script>
<style>
.border-table {
  border: 1px solid black;
}
</style>
